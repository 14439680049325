import React, { useRef, useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import getFluid from '../utils/get-fluid';
import NonConfigurableProduct from '../components/Products/Info/NonConfigurableProduct';
import FAQ from '../components/Products/FAQ';
import Description from '../components/Products/Description';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar';
import PopUpAdd from '../components/Products/PopUpAdd';
import pagesInfo from '../domain/pages-info';

export const promo = graphql`
  {
    lifestyle1: file(
      relativePath: { eq: "product-page/evabottle/gallery/evabottle_01.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    lifestyle2: file(
      relativePath: { eq: "product-page/evabottle/gallery/evabottle_02.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    lifestyle3: file(
      relativePath: { eq: "product-page/evabottle/gallery/evabottle_03.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    lifestyle4: file(
      relativePath: { eq: "product-page/evabottle/gallery/evabottle_04.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    lifestyle5: file(
      relativePath: { eq: "product-page/evabottle/gallery/evabottle_05.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    image: file(
      relativePath: { eq: "product-page/evabottle/evabottle_desc.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;

const info = pagesInfo.evabottle;

export default function Evabottle() {
  const infoRef = useRef(null);
  const [selected, setData] = useState({});

  return (
    <StaticQuery
      query={promo}
      render={(data) => (
        <main>
          <NavBar />
          <NonConfigurableProduct
            data={info}
            productName={info.title}
            fluids={
              new Map([
                [
                  'common',
                  [
                    'lifestyle1',
                    'lifestyle2',
                    'lifestyle3',
                    'lifestyle4',
                    'lifestyle5',
                  ].map((key) => getFluid(data, key)),
                ],
              ])
            }
            ref={infoRef}
            onChange={setData}
          />
          <Description
            type="evabottle"
            images={[data.image.childImageSharp.gatsbyImageData]}
          />
          <FAQ />
          <PopUpAdd data={selected} targetRef={infoRef} />
          <Footer />
        </main>
      )}
    />
  );
}
